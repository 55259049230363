<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          label-width="70px"
          :inline="true"
          :model="form"
          @submit.native.prevent
        >
          <el-form-item label="供应商">
            <el-select
              v-model="form.supplierIds"
              multiple
              filterable
              clearable
              collapse-tags
              @change="handleBlur"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.baseSupplierGetValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提货地址">
            <el-input
              clearable
              v-model="form.pickCityName"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
              @blur="handleBlur"
              placeholder="请输入提货地址"
            />
          </el-form-item>
          <el-form-item label="送货地址">
            <el-input
              clearable
              v-model="form.dispatchCityName"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
              @blur="handleBlur"
              placeholder="请输入送货地址"
            />
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="beginTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              unlink-panels
              @change="pickerClick"
              style="width: 350px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item v-show="!fold" label="产品专员">
            <el-select
              v-model="form.productCommissionerId"
              @change="handleBlur"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.productCommissionerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="编号" v-show="!fold">
            <el-input
              clearable
              v-model="form.code"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
              @blur="handleBlur"
              placeholder="请输入编号"
            />
          </el-form-item>
          <!-- <el-form-item label="是否含税" v-show="!fold">
            <el-select
              v-model="form.includingTaxes"
              @change="handleBlur"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.taxList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="失效时间" v-show="!fold">
            <el-date-picker
              v-model="beginTimeOne"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsOne"
              unlink-panels
              @change="pickerClickOne"
              style="width: 350px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="状态" v-show="!fold">
            <el-select
              v-model="form.status"
              filterable
              clearable
              @change="handleBlur"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in optionListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="20">
        <el-dialog
          title="导入"
          :visible.sync="dialogVisible"
          width="500px"
          :before-close="handleClose"
        >
          <span>
            <el-upload
              class="upload-demo"
              drag
              :action="importExectUrl()"
              :http-request="importExect"
              with-credentials
              :show-file-list="false"
              v-loading="loading"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </el-upload>
          </span>

          <span slot="footer" class="dialog-footer">
            <!-- <el-button v-show="haveFail" @click="errorLink()">
              导出错误跳转查看
            </el-button> -->
            <el-button @click="dialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>
        <el-button
          icon="el-icon-download"
          size="mini"
          type="primary"
          @click="baseyCode('', 'add')"
        >
          下载模板
        </el-button>
        <el-button
          icon="el-icon-upload"
          size="mini"
          type="primary"
          @click="dialogVisible = true"
        >
          导入
        </el-button>
        <el-button
          icon="el-icon-circle-check"
          size="mini"
          type="primary"
          @click="handleAdd('', 'commit')"
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
        >
          提交
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
          @click="handleAdd('', 'invalid')"
        >
          <vab-icon icon="forbid-2-line" />
          失效
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="handleAdd('', 'statusBack')"
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
        >
          <vab-icon icon="logout-circle-line" />
          状态回退
        </el-button>

        <el-button
          icon="el-icon-download"
          size="mini"
          type="primary"
          @click="handExport"
          :loading="anLoading"
        >
          导出
        </el-button>
        <el-button
          icon="el-icon-delete"
          type="danger"
          size="mini"
          @click="handleAdd('', 'delete')"
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
        >
          删除
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="4">
        <el-checkbox
          v-model="isQueryAll"
          label="操作查询到的全部数据"
        />
      </vab-query-form-right-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 330px)">
      <vxe-table
        border
        show-overflow
        height="100%"
        :row-config="{ isHover: true, isCurrent: true, height: 40 }"
        :data="list"
        :scroll-y="{ enabled: true }"
        v-loading="listLoading"
        :checkbox-config="{ trigger: 'row', range: true }"
        @checkbox-change="selectAllEvent"
        @checkbox-all="selectAllEvent"
        ref="tableSort"
        :row-class-name="rowStyle"
        :resizable="true"
      >
        <!-- <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
      element-loading-text="拼命加载中"
      @selection-change="handleSelectionChange"
      header-cell-class-name="table-header"
      class="table eleChange"
      :row-style="{ height: '30px' }"
      :cell-style="{ padding: '2px' }"
      :row-class-name="rowStyle"
      @row-click="handleRowClick"
    > -->
        <vxe-column align="center" fixed="left" type="checkbox" width="55" />
        <vxe-column
          align="center"
          field="route"
          title="线路"
          fixed="left"
          width="120"
        />
        <vxe-column align="center" field="supplier" title="供应商" width="80" />
        <vxe-colgroup title="提货地址" align="center">
          <vxe-column
            field="pickCityName"
            align="center"
            title="起运市"
            width="80"
          ></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="送货地址" align="center">
          <vxe-column
            field="dispatchCityName"
            align="center"
            title="目的市"
            width="80"
          ></vxe-column>
        </vxe-colgroup>
        <vxe-column
          title="采购比例"
          width="80"
          align="center"
          field="purchaseRatio"
        />
        <vxe-column
          title="运营加成比例"
          align="center"
          field="operationMarkupRatio"
          width="80"
        />
        <vxe-column
          title="利润加成比例"
          align="center"
          field="profitMarkupRatio"
          width="80"
        />
        <vxe-column
          title="最低标准价"
          align="center"
          field="lowestStandardPrice"
          width="80"
        />
        <vxe-column
          title="最低利润"
          width="80"
          align="center"
          field="minimumProfit"
        />
        <vxe-column align="center" field="ageing" title="时效" width="80" />
        <vxe-column
          align="center"
          field="includingTaxes"
          title="是否含税"
          width="80"
        />
        <vxe-column title="备注" width="350" align="center" field="remark" />
        <vxe-column
          title="生效日"
          align="center"
          field="effectiveDate"
          width="110"
        />
        <vxe-column
          title="失效日"
          align="center"
          field="expiringDate"
          width="110"
        />
        <vxe-column
          title="创建人"
          align="center"
          field="createBy"
          width="110"
        />
        <vxe-column
          title="创建时间"
          align="center"
          field="createTime"
          width="110"
        />
        <vxe-column
          title="修改人"
          align="center"
          field="updateBy"
          width="110"
        />
        <vxe-column
          title="修改时间"
          align="center"
          field="updateTime"
          width="110"
        />
        <vxe-column title="编号" align="center" field="code" width="110" />
        <vxe-column
          title="产品专员"
          align="center"
          field="productCommissioner"
          fixed="right"
          width="100"
        />
        <vxe-column
          title="状态"
          fixed="right"
          align="center"
          field="status"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == '待提交'">
              <el-tag>{{ scope.row.status }}</el-tag>
            </span>
            <span v-if="scope.row.status == '已提交'">
              <el-tag type="danger">{{ scope.row.status }}</el-tag>
            </span>
            <span v-if="scope.row.status == '失效'">
              <el-tag type="info">{{ scope.row.status }}</el-tag>
            </span>
          </template>
        </vxe-column>
        <!-- <vxe-column
        align="center"
        field="dealCustomerList"
        title="头部成交客户"
      /> -->
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 500, 1000]"
    />
  </div>
</template>
<script>
  import {
    urbanDistributionCostList,
    urbanDistributionCostBulkOperations,
    urbanDistributionCostImportUrl,
    urbanDistributionCostImport,
    urbanDistributionCostExport,
    baseTemplateImportGetFileByCode,
  } from '@/api/productCenter'
  export default {
    props: {
      optionList: Object,
    },
    data() {
      return {
        anLoading: false,
        fold: true,
        listLoading: false,
        height: this.$baseTableHeight(2) - 50,
        list: [],
        form: {
          supplierIds: [],
          pickCityName: '',
          dispatchCityName: '',
          beginDate: '',
          endDate: '',
          expiringBeginDate: '',
          expiringEndDate: '',
          productCommissionerId: '',
          status: '',
          code: '',
          // includingTaxes: '',
          pageNum: 1,
          pageSize: 100,
        },
        isQueryAll: false,
        opereteAllList: ['commit', 'invalid', 'statusBack', 'release','delete'],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        optionListTwo: [
          {
            id: '0',
            name: '待提交',
          },
          {
            id: '1',
            name: '已提交',
          },
          {
            id: '2',
            name: '失效',
          },
        ],
        beginTime: [],
        beginTimeOne: [],
        pickerOptions: {},
        pickerOptionsOne: {},
        bulkOperations: {
          type: '',
          idList: [],
        },
        dialogVisible: false,
        haveFail: false,
        loading: false,
      }
    },
    mounted() {
      // this.queryList()
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
      },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      handleSizeChange(val) {
        this.form.pageNum = 1
        this.form.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.form.pageNum = val
        this.queryList()
      },
      pickerClick() {
        this.form.beginDate = this.beginTime ? this.beginTime[0] : ''
        this.form.endDate = this.beginTime ? this.beginTime[1] : ''
        this.queryList()
      },
      pickerClickOne() {
        this.form.expiringBeginDate = this.beginTimeOne
          ? this.beginTimeOne[0]
          : ''
        this.form.expiringEndDate = this.beginTimeOne
          ? this.beginTimeOne[1]
          : ''
        this.queryList()
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleBlur() {
        this.form.pageNum = 1
        this.queryList()
      },
      async queryList() {
        this.bulkOperations.idList = []
        this.listLoading = true
        const res = await urbanDistributionCostList(this.form)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
      //导入
      importExectUrl() {
        let val = urbanDistributionCostImportUrl()
        return val
      },
      async importExect(param) {
        var file = param.file
        //发送请求的参数格式为FormData
        const formData = new FormData()
        formData.append('file', file)
        urbanDistributionCostImport(formData)
        this.loading = true
        this.timer = setInterval(() => {
          this.loading = false
          clearInterval(this.timer)
          this.errorLink()
        }, 2000)
        // console.log(res)
        // if (res.code == 200) {
        //   this.$message({
        //     message: res.data.info,
        //     type: 'warning',
        //   })
        // this.haveFail = res.data.haveFail
        // this.queryList()
        // }
      },

      selectAllEvent({ records }) {
        this.bulkOperations.idList = records.map(
          (item, index, array) => item.id
        )
      },
      // handleSelectionChange(val) {
      //   this.bulkOperations.idList = val.map((item, index, array) => item.id)
      // },
      rowStyle({ row }) {
        var arr = this.bulkOperations.idList
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      async handleAddAll(fn) {
        let msg = ''
        if (fn == 'commit') {
          msg = '提交'
        } else if (fn == 'invalid') {
          msg = '失效'
        } else if (fn == 'statusBack') {
          msg = '状态回退'
        }else if(fn == 'delete'){
          msg = '删除'
        }
        this.$baseConfirm(
          `确认${msg}当前查询到的全部数据吗？`,
          '提示',
          async () => {
            this.bulkOperations.type = fn
            const payload = {
              operateAll: true,
              ...this.bulkOperations,
              input: this.form,
            }
            const res = await urbanDistributionCostBulkOperations(payload)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }
        )
      },
      async handleAdd(index, fn) {
        if (this.isQueryAll && this.opereteAllList.includes(fn)) {
          return this.handleAddAll(fn)
        }
        let msg = ''
        if (this.bulkOperations.idList.length == 0) {
          // alert('选中内容为空！无法操作')
          return
        } else {
          console.log(fn)
          if (fn == 'commit') {
            msg = '确定提交吗'
          } else if (fn == 'invalid') {
            msg = '确定失效吗'
          } else if (fn == 'statusBack') {
            msg = '确定状态回退吗'
          } else if (fn == 'delete') {
            msg = '确定删除吗'
          }
          this.$baseConfirm(msg, '提示', async () => {
            this.bulkOperations.type = fn
            const res = await urbanDistributionCostBulkOperations(
              this.bulkOperations
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      // 模板下载
      async baseyCode() {
        const res = await baseTemplateImportGetFileByCode({
          code: 'UrbanDistributionCostImport',
        })
        window.open(res.data.url)
      },
      //导出
      async handExport() {
        this.anLoading = true
        this.$set(this.form, 'ids', this.bulkOperations.idList)
        // this.$baseConfirm('确定导出吗', '提示', async () => {
        const res = await urbanDistributionCostExport(this.form)
        const link = document.createElement('a')
        link.href = res.data.url
        link.click()
        this.anLoading = false
        this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        // })
      },
      handleClose() {
        this.dialogVisible = false
      },
      errorLink() {
        this.dialogVisible = false
        // this.$router.push('/exportLog/exportLog')
        this.$router.push({
          name: 'ExportLog',
          params: {
            type: 'upload/UrbanDistributionCost',
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      text-align: center;
    }
    .rowStyle {
      background-color: #e8f7fd !important;
      // color: #1890ff;
    }
  }
</style>
