<template>
  <div class="dashboard-container">
    <div>
      <el-tabs v-model="type"  @tab-click="handleClick">
        <el-tab-pane label="城配成本" name="1">
          <urban-distribution-cost
            :optionList="optionList"
            ref="tableOne"
          ></urban-distribution-cost>
        </el-tab-pane>
        <el-tab-pane label="城配服务" name="2">
          <urban-distribution-services
            :optionList="optionList"
            ref="tableTwo"
          ></urban-distribution-services>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import UrbanDistributionCost from './components/UrbanDistributionCost.vue'
import UrbanDistributionServices from './components/UrbanDistributionServices.vue'
import { productCommissionerGetValidList,baseSupplierGetValid } from '@/api/inquiryManagement'
export default {
  name:'UrbanDistributionProducts',
  components: { UrbanDistributionCost, UrbanDistributionServices },
  data() {
    return {
      type: '1',
      optionList: {
        costomerGetValidList: [
          {
            id: '',
            name: '',
          },
        ],
        productCommissionerList: [], //产品专员
        baseSupplierGetValidList:[],//供应商
        taxList:[
          {
            id:1,
            name:'是'
          },
          {
            id:0,
            name:'否'
          }
        ]//是否含税
      },
    }
  },
  // mounted() {
  //   this.selectList()
  //   this.queryList()
  // },
  activated(){
    this.selectList()
    this.queryList()
  },
  methods: {
    queryList() {
      this.$nextTick(() => {
        if (this.type == '1') {
          this.$refs['tableOne'].queryList()
        } else {
          this.$refs['tableTwo'].queryList()
        }
      })
    },
    handleClick(){
      this.queryList()
    },
    async selectList() {
      Promise.all(
        [
          productCommissionerGetValidList({}),
          baseSupplierGetValid({}),
        ].map((v) => v.catch((e) => console.log(e)))
      ).then((res) => {
        this.optionList.productCommissionerList = res[0].data
        this.optionList.baseSupplierGetValidList = res[1].data
      })

      // const red = await productCommissionerGetValidList({})
      // this.optionList.productCommissionerList = red.data
      // const req = await baseSupplierGetValid({})
      // this.optionList.baseSupplierGetValidList = req.data
    },
  },
}
</script>
<style lang="scss">
  .el-tooltip__popper{
    max-width:20%
  }
</style>